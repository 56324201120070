import React from "react";
import Layout from "../components/Layout";

const NotFoundPage = () => (
  <Layout>
    <div
      style={{
        textAlign: "center",
        fontSize: "1.5rem",
        paddingTop: "10rem",
        paddingRight: ".25rem",
        paddingLeft: ".25rem"
      }}
    >
      <h1>Thanks for your submission!</h1>
      <p>We'll follow up with your inquiry as soon as possible.</p>
    </div>
  </Layout>
);

export default NotFoundPage;
